.home-section {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
}

.home-section img#mountain {
  overflow-x: hidden;
  position: absolute;
  height: 110%;
  width: 100%;

  pointer-events: none;
}
.home-section img#stars {
  overflow-x: hidden;
  position: absolute;
  transition: top 1s;
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  left: 0;
}

.home-section img#moon {
  margin-top: 30px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: top 1s;
}

#mountain {
  padding-top: 350px;
  transition: top 0.1s;
}
