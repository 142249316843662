.accordion_li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
  overflow: hidden;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  position: relative;
  border-radius: 12px;
}

.accordion_ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 10px;
  overflow: hidden;
}
.accordion {
  overflow: hidden;
  position: relative;
}

.accordion_img {
  display: block;
  cursor: default;
  max-width: unset;
  object-fit: cover;
  border-radius: 12px;
}

.pv-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding: 20px;
  color: white;
}

.widthHover {
  width: 20%;
}
.widthHover:hover {
  width: 60%;
}
.bg-image {
  display: flex;
  justify-content: center;
}

.gradient-bg {
  background: -moz-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(249, 249, 249, 0.89) 100%
  );

  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(249, 249, 249, 0.89) 100%
  );
  background: -o-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(249, 249, 249, 0.89) 100%
  );
  background: -ms-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(249, 249, 249, 0.89) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(249, 249, 249, 0.89) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
}
/* .img-gradient {
  max-width: 100%;
  width: 800px;
  margin: auto;
} */

.img-gradient::after {
  display: block;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0,
    rgba(85, 85, 85, 0) 50%,
    rgb(0, 0, 0) 100%
  );
  margin-top: -400px;
  height: 400px;
  width: 100%;
  content: "";
}
